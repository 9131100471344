
import { defineComponent, ref, toRefs, watch, onMounted } from "vue";
import axios from "axios";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "AutoCuenta",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  emits: ["dataAutoCuenta"],
  setup(props, { emit }) {
    const formAutoCuentaRef = ref();
    const autoCuentaModalRef = ref<null | HTMLElement>(null);
    const brands = ref<any>();
    const models = ref<any>();
    const years = ref<any>();
    const versions = ref<any>();
    let initialValues = ref({
      marca: "",
      modelo: "",
      anio: "",
      version: "",
      kilometraje: "",
      brand: "",
      model: "",
      year: "",
      vers: "",
    });

    const schema = Yup.object().shape({
      marca: Yup.string()
        .required("La marca es obligatoria")
        .notOneOf([""], "La marca es obligatoria")
        .label("Marca"),
      modelo: Yup.string()
        .required("El modelo es obligatorio")
        .notOneOf([""], "El modelo es obligatorio")
        .label("Modelo"),
      anio: Yup.string()
        .required("El año es obligatorio")
        .notOneOf([""], "El año es obligatorio")
        .label("Año"),
      version: Yup.string()
        .required("La versión es obligatoria")
        .notOneOf([""], "La versión es obligatoria")
        .label("Version"),
      kilometraje: Yup.string()
        .required("El kilometraje es obligatorio")
        .label("Kilometraje"),
    });

    /**
     * @method catBrands
     * @param none
     * Obtiene el catálogo de marcas de vehículos
     */
    const catBrands = async () => {
      try {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + "/api/maxi/brands/v_1"
        );
        brands.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    /**
     * @method changeBrand
     * @param none
     * Obtiene el catálogo de modelos de vehículos al seleccionar una marca
     */
    const changeBrand = async (evt) => {
      initialValues.value.brand =
        evt.target.options[evt.target.options.selectedIndex].text;
      if (initialValues.value.marca != "") {
        try {
          const { data } = await axios.get(
            process.env.VUE_APP_API_URL +
              "/api/maxi/models/" +
              initialValues.value.marca
          );
          if (data.data.length > 0) {
            models.value = data.data;
            initialValues.value.modelo = "";
            years.value = [];
            initialValues.value.anio = "";
            versions.value = [];
            initialValues.value.version = "";
          } else {
            showAlerts("No hay modelos registrados");
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const changeModel = async (evt) => {
      initialValues.value.model =
        evt.target.options[evt.target.options.selectedIndex].text;
      if (initialValues.value.marca != "" && initialValues.value.modelo != "") {
        try {
          const { data } = await axios.get(
            process.env.VUE_APP_API_URL +
              "/api/maxi/years/" +
              initialValues.value.modelo
          );
          if (data.data.length > 0) {
            years.value = data.data;
            versions.value = [];
            initialValues.value.version = "";
            initialValues.value.anio = "";
          } else {
            showAlerts("No hay años registrados");
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const changeYear = async (evt) => {
      initialValues.value.year =
        evt.target.options[evt.target.options.selectedIndex].text;
      if (
        initialValues.value.marca != "" &&
        initialValues.value.modelo != "" &&
        initialValues.value.anio != ""
      ) {
        try {
          const { data } = await axios.get(
            process.env.VUE_APP_API_URL +
              "/api/maxi/versions/" +
              initialValues.value.anio
          );
          if (data.data.length > 0) {
            versions.value = data.data;
            initialValues.value.version = "";
          } else {
            showAlerts("No hay versiones registradas");
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const changeVersion = async (evt) => {
      initialValues.value.vers =
        evt.target.options[evt.target.options.selectedIndex].text;
    };

    const showAlerts = (error) => {
      Swal.fire({
        position: "center",
        icon: "error",
        title: error,
        showConfirmButton: true,
      });
    };

    const formatPrice = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const addKm = () => {
      let kilometraje = formatPrice(initialValues.value.kilometraje);
      if (!isNaN(parseInt(kilometraje))) {
        initialValues.value.kilometraje = kilometraje;
      } else {
        initialValues.value.kilometraje = "";
      }
    };

    const saveAutoCuenta = async () => {
      hideModal(autoCuentaModalRef.value);
      emit("dataAutoCuenta", {
        data: initialValues.value,
      });
    };

    const onInvalidSubmit = (values) => {
      console.log(values);
    };

    onMounted(async () => {
      await catBrands();
    });

    return {
      autoCuentaModalRef,
      initialValues,
      catBrands,
      changeBrand,
      changeModel,
      changeYear,
      changeVersion,
      brands,
      models,
      years,
      versions,
      schema,
      saveAutoCuenta,
      formAutoCuentaRef,
      onInvalidSubmit,
      showAlerts,
      addKm,
      formatPrice
    };
  },
});
