<template>
  <div>
    <div class="loader-container" v-if="showLoader">
      <svg height="100" width="100">
        <circle
          stroke-linecap="round"
          transform-origin="50 50"
          cx="50"
          cy="50"
          r="40"
          stroke-width="5"
          stroke="white"
          fill="none"
          stroke-dasharray="125.66"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="1s"
            to="360"
            repeatCount="indefinite"
          />
        </circle>
        <circle
          stroke-linecap="round"
          transform-origin="50 50"
          cx="50"
          cy="50"
          r="30"
          stroke-width="5"
          stroke="white"
          fill="none"
          stroke-dasharray="94.245"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur=".8s"
            to="360"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center py-5 bgTitulo">
        <h1 class="fs-1 fw-bold">Apartar vehículo</h1>
      </div>
    </div>
    <div class="container-lg mt-10">
      <Form
        class="form"
        @invalid-submit="onInvalidSubmitMain"
        @submit="saveApartar"
        :validation-schema="schemaMain"
        autocomplete="off"
        id="payment-form"
      >
        <div class="row">
          <div class="col-xs-12 col-md-8 col-lg-8 px-3 px-lg-8">
            <h2 class="mb-8 fs-2 fw-bold">Datos del contacto</h2>
            <div class="mb-10">
              <div class="row ms-0 borderPanel">
                <h3 class="py-5 ps-7 fs-5 fw-bold fondoAzul">Ingresa tus datos</h3>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 px-5 ps-10 mt-5">
                  <label class="form-label fs-7 ms-3 fw-bold text-dark"
                    >Nombre(s) <span class="text-danger">*</span></label
                  >
                  <Field
                    class="form-control form-control-sm form-control-solid fs-base inputFormat"
                    type="text"
                    name="nombres"
                    v-model="initialValuesMain.nombres"
                    ref="nombres"
                    autocomplete="off"
                    placeholder="Roberto Darío"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="nombres" />
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 px-5 pe-10 mt-5">
                  <label class="form-label fs-7 ms-3 fw-bold text-dark"
                    >Apellido(s) <span class="text-danger">*</span></label
                  >
                  <Field
                    class="form-control form-control-sm form-control-solid fs-base inputFormat"
                    type="text"
                    name="apellidos"
                    v-model="initialValuesMain.apellidos"
                    ref="apellidos"
                    autocomplete="off"
                    placeholder="Covarrubias Mendoza"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="apellidos" />
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 px-5 ps-10 mt-5 mb-5">
                  <label class="form-label fs-7 ms-3 fw-bold text-dark"
                    >Correo <span class="text-danger">*</span></label
                  >
                  <div class="position-relative">
                    <Field
                      class="form-control form-control-sm form-control-solid fs-base inputFormat ps-10"
                      type="email"
                      name="correo"
                      v-model="initialValuesMain.correo"
                      ref="correo"
                      autocomplete="off"
                      placeholder="ejemplo@correo.com"
                    />
                    <div
                      class="position-absolute translate-middle-y top-50 start-0 ms-3 mt-1"
                    >
                      <span
                        role="button"
                        class="svg-icon svg-icon-1hx pointer fs-base fw-normal"
                      >
                        <i class="fas fa-at text-white"></i>
                      </span>
                    </div>
                  </div>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="correo" />
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 px-5 pe-10 mt-5 mb-5">
                  <label class="form-label fs-7 ms-3 fw-bold text-dark"
                    >Teléfono <span class="text-danger">*</span></label
                  >
                  <div class="position-relative">
                    <Field
                      class="form-control form-control-sm form-control-solid fs-base inputFormat ps-10"
                      type="text"
                      name="telefono"
                      ref="telefono"
                      v-model="initialValuesMain.telefono"
                      autocomplete="off"
                      placeholder="333-333-3333"
                      v-maska="{
                        mask: 'Z##-###-####',
                        tokens: { Z: { pattern: /[1-9]/ } },
                      }"
                    />
                    <div
                      class="position-absolute translate-middle-y top-50 start-0 ms-3 mt-1"
                    >
                      <span
                        role="button"
                        class="svg-icon svg-icon-1hx pointer fs-base fw-normal"
                      >
                        <i class="fas fa-phone text-white"></i>
                      </span>
                    </div>
                  </div>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="telefono" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2 class="mb-8 fs-2 fw-bold">Método de pago</h2>
            <div class="mb-10">
              <div class="row ms-0 borderPanel">
                <div class="bg-primary w-100 py-5 rounded-top">
                  <div class="row">
                    <div class="col-sm-12 col-lg-6 d-flex align-items-center">
                      <h3 class="ms-2 fs-5 fw-bold">Tarjeta de crédito o débito</h3>
                    </div>
                    <!-- <div class="col-sm-12 col-lg-9 p-0">
                        <div class="d-flex align-items-start">
                            <div class="badge d-flex align-items-center p-1 bg-white w-75px h-30px m-1">
                                <img class="img-fluid" src="media/images/openpay/visa.png" alt="Visa">
                            </div>
                            <div class="badge p-1 bg-white w-75px  h-30px m-1">
                                <img class="img-fluid h-25px" src="media/images/openpay/mastercard.png" alt="Mastercard">
                            </div>
                            <div class="badge d-flex align-items-center justify-content-center p-1 bg-white w-75px h-30px m-1">
                                <img class="img-fluid h-15px" src="media/images/openpay/bancos/BBVA.png" alt="BBVA">
                            </div>
                            <div class="badge d-flex align-items-center p-1 bg-white w-75px h-30px m-1">
                                <img class="img-fluid" src="media/images/openpay/bancos/banorte.png" alt="Banorte">
                            </div>
                            <div class="badge d-flex align-items-center p-1 bg-white w-75px h-30px m-1">
                                <img class="img-fluid" src="media/images/openpay/bancos/citibanamex.png" alt="Citibanamex">
                            </div>
                            <div class="badge d-flex align-items-center justify-content-center p-1 bg-white w-70px h-30px m-1">
                                <img class="img-fluid h-30px" src="media/images/openpay/bancos/hsbc.png" alt="HSBC">
                            </div>
                            <div class="badge d-flex align-items-center p-1 bg-white w-75px h-30px m-1">
                                <img class="img-fluid" src="media/images/openpay/bancos/santander.png" alt="Santander">
                            </div>
                        </div>
                    </div> -->
                  </div>
                </div>
                <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 px-5 mt-8 pe-10 ps-10" style="border-right: 1px solid #26252b;">
                  <label class="form-label fs-7 ms-3 fw-bold text-dark">Tarjetas de crédito</label>
                  <img class="img-fluid" src="media/images/openpay/credir_cards.png" alt="Credit Cards">
                </div>
                <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 px-5 mt-8 pe-10 ps-10">
                  <label class="form-label fs-7 ms-3 fw-bold text-dark">Tarjetas de débito</label>
                  <img class="img-fluid" src="media/images/openpay/debit_cards.png" alt="Debit Cards">
                </div>
                <div
                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-5 mt-5 mb-5 pe-10 ps-10"
                >
                  <label class="form-label fs-7 ms-3 fw-bold text-dark"
                    >Nombre que aparece en la tarjeta
                    <span class="text-danger">*</span></label
                  >
                  <Field
                    class="form-control form-control-sm form-control-solid fs-base inputFormat"
                    type="text"
                    name="nombreTarjeta"
                    ref="nombreTarjeta"
                    v-model="initialValuesMain.nombreTarjeta"
                    autocomplete="off"
                    placeholder="Roberto Darío Covarrubias Mendoza"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="nombreTarjeta" />
                    </div>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-5 mb-5 pe-10 ps-10"
                >
                  <label class="form-label fs-7 ms-3 fw-bold text-dark"
                    >Número de la tarjeta <span class="text-danger">*</span></label
                  >
                  <Field
                    class="form-control form-control-sm form-control-solid fs-base inputFormat"
                    type="text"
                    name="numeroTarjeta"
                    ref="numeroTarjeta"
                    v-model="initialValuesMain.numeroTarjeta"
                    autocomplete="off"
                    placeholder="5256 7821 4478 1890"
                    v-maska="{
                      mask: '#### #### #### ####',
                    }"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="numeroTarjeta" />
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 px-5 mb-5 ps-10">
                  <label class="form-label fs-7 ms-3 fw-bold text-dark"
                    >Expiración mes <span class="text-danger">*</span></label
                  >
                  <div class="position-relative">
                    <Field
                      class="form-control form-control-sm form-control-solid fs-base inputFormat ps-10"
                      type="number"
                      name="mes"
                      v-model="initialValuesMain.mes"
                      min="1"
                      max="12"
                      ref="mes"
                      autocomplete="off"
                    />
                  </div>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="mes" />
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 px-5 mb-5">
                  <label class="form-label fs-7 ms-3 fw-bold text-dark"
                    >Expiración año <span class="text-danger">*</span></label
                  >
                  <Field
                    class="form-control form-control-sm form-control-solid fs-base inputFormat"
                    type="number"
                    :min="currentYear"
                    max="99"
                    v-model="initialValuesMain.anio"
                    name="anio"
                    ref="anio"
                    autocomplete="off"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="anio" />
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 px-5 mb-5 pe-10"
                >
                  <label class="form-label fs-7 ms-3 fw-bold text-dark"
                    >CVV <span class="text-danger">*</span></label
                  >
                  <div class="position-relative w-100">
                    <Field
                    class="form-control form-control-sm form-control-solid fs-base inputFormat"
                    :type="showCvv ? 'text' : 'password'"
                    min="1"
                    name="cvv"
                    ref="cvv"
                    v-model="initialValuesMain.cvv"
                    autocomplete="off"
                    placeholder="***"
                  ></Field>
                  <span
                    class="position-absolute top-50 end-0 cursor-pointer translate-middle-y"
                    style="margin-right: 10px;"
                    @click="showCvv = !showCvv"
                  >
                    <i
                      :class="showCvv ? 'bi bi-eye': 'bi bi-eye-slash'"
                      class="bi bi-eye-slash fs-7 text-white"
                    ></i>
                  </span>
                  </div>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="cvv" />
                    </div>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-sm-8 col-md-8 col-lg-8 px-5 mb-5 ps-10 pe-10"
                >
                  <div class="form-check form-check-custom form-check-solid">
                    <input
                      class="form-check-input checkBoxInput pt-5"
                      type="checkbox"
                      name="terminos"
                      id="terminos"
                      ref="terminos"
                      @click="changeChecked"
                    />
                    <a href="docs/TyC Solanaseminuevos.pdf" class="fw-light colorW" target="_blank">
                      <label class="form-check-label fs-xs" for="flexCheckDefault">
                        He leído y estoy de acuerdo con los términos y condiciones.
                      </label>
                    </a>

                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 px-5 mb-5 ps-5 pe-10">
                  <img src="media/images/openpay/cvv_cards.png" alt="Cvv cards" style="height:46px;">
                </div>

                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 px-5 mb-5 ps-5 pe-10">
                  <label class="form-check-label fs-xs" for="flexCheckDefault">
                    Transacciones realizadas vía:
                  </label>
                  <img src="media/images/openpay/openpayLogo.svg" alt="Openpay" style="height:40px;">
                </div>
                <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 px-5 mb-5 pe-10 mt-4 ps-10">
                  <img src="media/images/openpay/3dsecure.png" alt="3dSecure"  style="height: 48px; position: relative; top: -7px;">
                </div>

              </div>
            </div>
            <h2 class="fs-2 fw-bold mb-8">Vehículo a apartar</h2>
            <div class="mb-10">
              <div class="row ms-0 borderPanel">
                <h3 class="pt-5 ps-7 fs-5 fw-bold fondoAzul mb-0">
                  {{ marca }} {{ modelo }} {{ version }}
                </h3>
                <span class="py-5 ps-7 pt-2 fs-base azul spanDown"
                  >{{ year }} - {{ mileage }} km - {{ modelo }} - {{ transmission }}</span
                >
                <div
                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-5 mt-8 mb-5 pe-10 ps-10"
                >
                  <div
                    id="carouselExampleControls2"
                    class="carousel slide"
                    data-bs-interval="false"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-inner">
                      <div
                        class="carousel-item"
                        v-for="(img, index) in vehicleImages"
                        :key="index"
                        :class="index == 0 ? 'active' : ''"
                      >
                        <img
                          class="d-block w-100 imgCarousel"
                          :src="vehicleImages[indexImageFotos]"
                          alt="First slide"
                        />
                        <div class="carousel-caption">
                          <span class="counterCaption"
                            >{{ indexImageFotos + 1 }} - {{ vehicleImages.length }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <a class="carousel-control-prev" role="button">
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                        @click="prevImageFotos"
                        v-show="indexImageFotos > 0 && vehicleImages.length > 0"
                      ></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" role="button">
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                        v-show="
                          vehicleImages.length > 0 &&
                          indexImageFotos + 1 < vehicleImages.length
                        "
                        @click="nextImageFotos"
                      ></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-5 pe-10 ps-10">
                  <div class="containerImages">
                    <ul class="listadoImagenes p-0">
                      <li
                        class="liAbsolute"
                        v-for="(img, index) in vehicleImages"
                        :key="index"
                      >
                        <img
                          class="vehiculoImagen"
                          :src="img"
                          alt="Automóvil"
                          @click="setImageFotos(index)"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mt-16 px-3 px-md-5 px-lg-8">
            <h2 class="fs-1 fw-bold">{{ marca }} {{ modelo }} {{ version }}</h2>
            <span class="py-5 pt-2 fs-base grayColor mb-10"
              >{{ year }} - {{ mileage }} km - {{ modelo }} - {{ transmission }}</span
            >
            <div class="mt-5 pb-2 borderBottom">
              <span class="fs-base">Monto</span>
              <span class="fs-base floatRight">$ 4,310.34</span>
            </div>
            <div class="mt-3 pb-2 borderBottom">
              <span class="fs-base">Concepto</span>
              <span class="fs-base floatRight">Apartado</span>
            </div>
            <div class="mt-3 pb-2 borderBottom">
              <span class="fs-base">IVA</span>
              <span class="fs-base floatRight">$ 689.66</span>
            </div>
            <div class="mt-3 pb-2 borderBottom mb-10">
              <span class="fs-base">Apartado del vehículo</span>
              <span class="fs-base floatRight">$ 5,000.00</span>
            </div>
            <h2 class="fs-5 fw-bold">Total a pagar</h2>
            <h2 class="mb-10 greenColor fs-1 fw-bold">$ 5,000.00</h2>
            <button
              type="submit"
              class="btn btn-primary mb-5 btn-block w100"
              :disabled="!isChecked"
            >
              <span class="fs-base fw-bold"> Apartar vehículo $ 5,000.00 </span>
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-block w100"
              data-bs-toggle="modal"
              data-bs-target="#autoCuentaModal"
            >
              <span class="fs-base fw-bold">Dar mi vehículo a cuenta</span>
            </button>
          </div>
        </div>
      </Form>
    </div>
    <ModalAutoCuenta @dataAutoCuenta="getDataAutoCuenta($event)"></ModalAutoCuenta>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import { useRoute } from "vue-router";
import ModalAutoCuenta from "@/components/vehicles/ModalAutoCuenta.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ApartarVehicle",
  components: {
    Field,
    Form,
    ErrorMessage,
    ModalAutoCuenta,
  },
  setup() {
    const route = useRoute();
    const deviceId = (ref < HTMLElement) | (null > null);
    const marca = ref("");
    const isChecked = ref(false);
    const showLoader = ref(false);
    const modelo = ref("");
    const version = ref("");
    const mileage = ref("");
    const year = ref(0);
    const transmission = ref("");
    const vehicleImages = ref([]);
    const indexImageFotos = ref(0);
    const router = useRouter();
    const merchantId = ref("");
    const publicKey = ref("");
    let currentYear = new Date().getFullYear().toString().substr(-2);
    const showCvv = ref(false);
    const initialValuesMain = ref({
      nombres: "",
      apellidos: "",
      correo: "",
      telefono: "",
      nombreTarjeta: "",
      numeroTarjeta: "",
      mes: "",
      anio: "",
      cvv: "",
      autoCuenta: {},
      device: "",
      tokenCard: "",
      accept_terms_of_service: isChecked.value,
    });

    const regexPhone = /^[1-9]\d{2}-\d{3}-\d{4}?$/;
    const regexCard = /^\d{4} \d{4} \d{4} \d{3,4}?$/;
    const regexCvv = /^\d{3,4}?$/;

    const schemaMain = Yup.object().shape({
      nombres: Yup.string().required("El nombre es obligatorio").label("nombres"),
      apellidos: Yup.string()
        .required("Los apellidos son obligatorios")
        .label("apellidos"),
      correo: Yup.string()
        .email("El formato del correo es incorrecto")
        .required("El correo es obligatorio")
        .label("correo"),
      telefono: Yup.string()
        .matches(regexPhone, "El teléfono es incorrecto")
        .required("El teléfono es obligatorio")
        .label("telefono"),
      nombreTarjeta: Yup.string()
        .required("El nombre del titular es obligatorio")
        .label("nombreTarjeta"),
      numeroTarjeta: Yup.string()
        .matches(regexCard, "El número de tarjeta es incorrecto")
        .required("El número de la tarjeta es obligatorio")
        .label("numeroTarjeta"),
      mes: Yup.string()
        .min(1, "Escribe por lo menos 1 dígitos")
        .max(2, "Escribe máximo 2 dígitos")
        .required("El mes es obligatorio")
        .label("mes"),
      anio: Yup.string()
        .min(2, "Escribe por lo menos 2 dígitos")
        .max(2, "Escribe máximo 2 dígitos")
        .required("El año es obligatorio")
        .label("anio"),
      cvv: Yup.string()
        .matches(regexCvv, "El cvv es incorrecto")
        .required("El cvv de la tarjeta es obligatorio")
        .min(3, "Escribe por lo menos 3 dígitos")
        .max(4, "Escribe máximo 4 dígitos")
        .label("cvv"),
    });

    let errores = {
      1000: "Ocurrió un error interno en el servidor de Openpay",
      1001: "El formato de la petición no es JSON, los campos no tienen el formato correcto, o la petición no tiene campos que son requeridos",
      1002: "La llamada no esta autenticada o la autenticación es incorrecta",
      1003: "La petición esta mal formada",
      1004: "Un servicio necesario para el procesamiento de la transacción no se encuentra disponible",
      1005: "Uno de los recursos requeridos no existe",
      1006: "Ya existe una transacción con el mismo ID de orden",
      1007: "La transferencia de fondos entre una cuenta de banco o tarjeta y la cuenta de Openpay no fue aceptada",
      1008: "Una de las cuentas requeridas en la petición se encuentra desactivada",
      1009: "El cuerpo de la petición es demasiado grande",
      1010: "Se esta utilizando la llave pública para hacer una llamada que requiere la llave privada, o bien, se esta usando la llave privada desde JavaScript",
      1011: "Se solicita un recurso que esta marcado como eliminado",
      1012: "El monto transacción esta fuera de los limites permitidos",
      1013: "La operación no esta permitida para el recurso",
      1014: "La cuenta esta inactiva",
      1015: "No se ha obtenido respuesta de la solicitud realizada al servicio",
      1016: "El mail del comercio ya ha sido procesada",
      1017: "El gateway no se encuentra disponible en ese momento",
      1018: "El número de intentos de cargo es mayor al permitido",
      1020: "El número de dígitos decimales es inválido para esta moneda",
      1023: "Se han terminado las transacciones incluidas en tu paquete. Para contratar otro paquete contacta a soporte@openpay.mx",
      1024: "El monto de la transacción excede su límite de transacciones permitido por TPV",
      1025: "Se han bloqueado las transacciones CoDi contratadas en tu plan",
      2001: "La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente",
      2003: "El cliente con este identificador externo (External ID) ya existe",
      2004: "El número de tarjeta es invalido",
      2005: "La fecha de expiración de la tarjeta es anterior a la fecha actual",
      2006: "El código de seguridad de la tarjeta (CVV2) no fue proporcionado",
      2007: "El número de tarjeta es de prueba, solamente puede usarse en Sandbox",
      2008: "La tarjeta no es valida para pago con puntos",
      2009: "El código de seguridad de la tarjeta (CVV2) es inválido",
      2010: "Autenticación 3D Secure fallida",
      2011: "Tipo de tarjeta no soportada",
      3001: "La tarjeta fue declinada por el banco",
      3002: "La tarjeta ha expirado",
      3003: "La tarjeta no tiene fondos suficientes",
      3004: "La tarjeta ha sido identificada como una tarjeta robada",
      3005: "La tarjeta ha sido rechazada por el sistema antifraude",
      3006: "La operación no esta permitida para este cliente o esta transacción",
      3009: "La tarjeta fue reportada como perdida",
      3010: "El banco ha restringido la tarjeta",
      3011: "El banco ha solicitado que la tarjeta sea retenida. Contacte al banco",
      3012: "Se requiere solicitar al banco autorización para realizar este pago",
      3201: "Comercio no autorizado para procesar pago a meses sin intereses",
      3203: "Promoción no valida para este tipo de tarjetas",
      3204: "El monto de la transacción es menor al mínimo permitido para la promoción",
      3205: "Promoción no permitida",
      4001: "La cuenta de Openpay no tiene fondos suficientes",
      4002: "La operación no puede ser completada hasta que sean pagadas las comisiones pendientes",
      6001: "El webhook ya ha sido procesado",
      6002: "No se ha podido conectar con el servicio de webhook",
      6003: "El servicio respondió con errores.",
    };

    const device_session_openpay = ref("");

    const getVehicleById = async (id) => {
      let url = process.env.VUE_APP_API_URL + "/api/vehicle/" + id;
      const { data } = await axios.get(url);
      if (data.success) {
        if (data.data.status.status == "Apartado") {
          router.push({
            name: "home",
          });
        }
        merchantId.value = data.data.agency.merchant;
        publicKey.value = data.data.agency.op_pk;
        marca.value = data.data.brand.brand;
        modelo.value = data.data.model.model;
        version.value = data.data.version.version;
        mileage.value = data.data.mileage;
        year.value = data.data.year.year;
        transmission.value = data.data.transmission.transmission;
        data.data.images.forEach((value, ind) => {
          if (value.type == "Vehiculo") {
            let path =
              process.env.NODE_ENV === "production"
                ? value.path
                : value.path.replace("localhost", "localhost:8000");
            vehicleImages.value.push(path);
          }
        });
      }
    };

    const prevImageFotos = () => {
      if (indexImageFotos.value > 0) {
        indexImageFotos.value--;
      }
    };

    const nextImageFotos = () => {
      indexImageFotos.value++;
    };

    const setImageFotos = (index) => {
      indexImageFotos.value = index;
    };

    const getDataAutoCuenta = async (evt) => {
      initialValuesMain.value.autoCuenta = await evt.data;

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Vehículo a cuenta cargado correctamente",
        showConfirmButton: true,
      });
    };

    const onInvalidSubmitMain = (values) => {
      console.log(values);
    };

    

    const saveApartar = async () => {
      showLoader.value = !showLoader.value;
      let tarjeta = initialValuesMain.value.numeroTarjeta;
      await fetch(
        "https://api.openpay.mx/v1/groups/" +
          process.env.VUE_APP_OP_GROUPID +
          "/tokens",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: "Basic " + btoa(process.env.VUE_APP_OP_PK + ":"),
          },
          body: JSON.stringify({
            card_number: tarjeta.replace(/ /g, ""),
            holder_name: initialValuesMain.value.nombreTarjeta,
            expiration_year: initialValuesMain.value.anio,
            expiration_month: initialValuesMain.value.mes,
            cvv2: initialValuesMain.value.cvv,
          }),
        }
      )
        .then((resp) => resp.json())
        .then(function (response) {
          if (response.id != undefined && response.id != "") {
            sendApartado(response.id);
          } else {
            if (response.error_code == 1001) {
              let error = response.description;
              if (response.description.includes("expiration_month")) {
                error = "El mes es incorrecto, meses válidos son del 01 al 12";
              }
              if (response.description.includes("be 4 digits")) {
                error = "El CVV es de al menos 4 dígitos";
              }
              if (response.description.includes("be 3 digits")) {
                error = "El CVV es de al menos 3 dígitos";
              }
              Swal.fire({
                title: "Error",
                text: error,
                icon: "error",
                confirmButtonText: "Entendido",
              }).then((result) => {
                if (result.isConfirmed) {
                  return false;
                }
              });
            } else {
              Swal.fire({
                title: "Error",
                text: errores[response.error_code],
                icon: "error",
                confirmButtonText: "Entendido",
              }).then((result) => {
                if (result.isConfirmed) {
                  return false;
                }
              });
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          showLoader.value = !showLoader.value;
        });
    };

    const changeChecked = () => {
      isChecked.value = !isChecked.value;
    };

    const sendApartado = async (idCard) => {
      let autoCuenta = {};
      if (initialValuesMain.value.autoCuenta.marca != undefined) {
        let kilometrajeFormat = initialValuesMain.value.autoCuenta.kilometraje.split(
          "."
        )[0];
        let kilFinal = kilometrajeFormat.replace(/,/g, "");
        autoCuenta = {
          marca: {
            id: initialValuesMain.value.autoCuenta.marca,
            name: initialValuesMain.value.autoCuenta.brand,
          },
          modelo: {
            id: initialValuesMain.value.autoCuenta.modelo,
            name: initialValuesMain.value.autoCuenta.model,
          },
          year: {
            id: initialValuesMain.value.autoCuenta.anio,
            name: initialValuesMain.value.autoCuenta.year,
          },
          version: {
            id: initialValuesMain.value.autoCuenta.version,
            name: initialValuesMain.value.autoCuenta.vers,
          },
          mileage: kilFinal,
        };
      }
      try {
        let url =
          process.env.VUE_APP_API_URL + "/api/site/vehicle/apartar/" + route.params.id;
        const { data } = await axios.post(url, {
          source_id: idCard,
          device_session_id: document.getElementById("deviceIdHiddenFieldName").value,
          customer: {
            name: initialValuesMain.value.nombres,
            last_name: initialValuesMain.value.apellidos,
            phone_number: initialValuesMain.value.telefono.replace(/-/g, ""),
            email: initialValuesMain.value.correo,
          },
          accept_terms_of_service: isChecked.value ? "Si" : "No",
          a_cuenta: autoCuenta,
        });
        if (data.success) {
          window.location.href = data.data.url;
        }
      } catch (error) {
        showLoader.value = !showLoader.value;
        Swal.fire({
          title: "Error",
          text: "La tarjeta fue rechazada",
          icon: "error",
          confirmButtonText: "Entendido",
        }).then((result) => {
          if (result.isConfirmed) {
            showLoader.value = !showLoader.value;
            return false;
          }
        });
      }
    };

    onMounted(async () => {
      if(route.params.error){
        let url = process.env.VUE_APP_API_URL + "/api/op-detail/" + route.params.error;
        const {data} = await axios.get(url);
        let telefonoFormat = '';
        let telefono = data.data.phone_number.split("");
        telefono.forEach((element, ind) => {
          if(ind == 3 || ind == 6){
            telefonoFormat += '-';
          }
          telefonoFormat += element;
        });
        
        initialValuesMain.value.nombres  = data.data.name;
        initialValuesMain.value.apellidos  = data.data.last_name;
        initialValuesMain.value.telefono  = telefonoFormat;
        initialValuesMain.value.correo = data.data.email;

        Swal.fire({
          title: "Error",
          text: data.data.error,
          icon: "error",
          confirmButtonText: "Entendido",
        });
      }
      if (route.params.id) {
        await getVehicleById(route.params.id);
        OpenPay.setId(merchantId.value);
        OpenPay.setApiKey(publicKey.value);
        OpenPay.setSandboxMode(false);
        OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName");
      }
    });

    return {
      getVehicleById,
      marca,
      modelo,
      version,
      mileage,
      year,
      transmission,
      vehicleImages,
      indexImageFotos,
      prevImageFotos,
      nextImageFotos,
      setImageFotos,
      getDataAutoCuenta,
      currentYear,
      onInvalidSubmitMain,
      saveApartar,
      initialValuesMain,
      schemaMain,
      isChecked,
      changeChecked,
      device_session_openpay,
      deviceId,
      sendApartado,
      merchantId,
      publicKey,
      showLoader,
      showCvv,
    };
  },
});
</script>
<style scoped>
.loader-container {
  width: 120%;
  height: 778%;
  background-color: #02000099;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  margin: 0;
  position: absolute;
  top: 118%;
  left: 55%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bgTitulo {
  background-color: #272b31;
}

.fondoAzul {
  background-color: #0860f0;
  border-radius: 10px 10px 0px 0px;
}

.azul {
  background-color: #0860f0;
}

.borderPanel {
  border: 1px solid #26252b;
  border-radius: 10px;
}

.inputFormat {
  border-color: #26252b;
}

.inputFormat::placeholder {
  color: #b9b9ba;
}

.checkBoxInput {
  height: 18px;
  width: 18px;
  background-color: #151317 !important;
  border: 1px solid #26252b !important;
}

.checkBoxInput:checked {
  background-color: #0860f0 !important;
}

.spanDown {
  font-size: 16px;
  color: #84b0f8;
}

.grayColor {
  color: #848385;
}

.floatRight {
  float: right;
}

.borderBottom {
  border-bottom: 1px solid white;
}

.greenColor {
  color: #17bf63;
}

.w100 {
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button {
  /*-webkit-appearance: none;*/
}

.containerImages {
  width: 100%;
  overflow-x: auto;
  position: relative;
  top: -35px;
}

.listadoImagenes {
  display: inline-flex;
  list-style-type: none;
}

.listadoImagenes li {
  margin-right: 15px;
  padding-top: 30px;
  margin-top: 10px;
}

.vehiculoImagen {
  width: 7vw;
  height: 115px;
  border-radius: 5px;
}

.vehiculoImagen:hover {
  cursor: pointer;
}

.carousel-control-prev-icon {
  background-color: black !important;
  padding: 25px 0px !important;
  position: relative !important;
  left: -47px !important;
}

.carousel-control-next-icon {
  background-color: black !important;
  padding: 25px 0px !important;
  position: relative !important;
  right: -47px !important;
}

.imgCarousel {
  border-radius: 10px;
}

@media screen and (max-width: 991px) and (min-width: 215px) {
  .vehiculoImagen {
    width: 17vw !important;
  }
}
</style>
